import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { DeliveryOptions } from "app/_components/checkout-line-item-delivery/checkout-line-item-delivery.component.state";
import { REGEX_PHONE_PATTERN } from "app/app.constants";
import { Branch } from "entities/branch";
import { CartResult, CartResultLineItem } from "entities/cart-result";
import { DeliveryTypes } from "entities/cart-search";
import { BillToInfo } from "entities/carts/bill-to-info";
import { SetDeliveryRequest } from "entities/carts/set-delivery-request";
import { UpdateCartRequest } from "entities/carts/update-cart-request";
import { addCreditCardTransactionResult } from "entities/checkout-billing-enums";
import { Customer } from "entities/customer";
import { DeliveryItems } from "entities/delivery-items";
import { OrderType } from "entities/order-type";
import { ShipToCustomer } from "entities/ship-to-customer";
import { StoredPaymentMethod } from "entities/stored-payment-method";
import { ToastType } from "entities/toast-type";
import { User } from "entities/user";
import { environment } from "environments/environment";
import { blankId } from "helpers/blank-id";
import { isCashCustomer } from "helpers/is-cash-customer";
import {
  catchError, combineLatest, debounceTime, distinctUntilChanged,
  filter, fromEventPattern, map, of,
  shareReplay,
  switchMap, tap,
  withLatestFrom
} from "rxjs";
import { CartService } from "services/cart.service";
import { CheckoutFormService } from "services/checkout-form.service";
import { FeatureFlagService } from "services/feature-flag.service";
import { LoaderService } from "services/loader.service";
import { ToastService } from "services/toast.service";
import { AppState } from "store/app-state";
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartActions from "store/cart/cart.actions";
import * as CartSelectors from 'store/cart/cart.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as CustomerActions from "store/customer/customer.actions";
import * as CustomerSelectors from "store/customer/customer.selectors";
import { CustomerState } from "store/customer/customer.state";
import { isFeatureActive } from "store/feature-flags/feature-flags.selectors";



@Injectable()
export class CheckoutFormEffects {

  reactOnFormChange$ = createEffect(() => combineLatest([
    this.checkoutFormService.checkoutForm.valueChanges,
    this.store.select(CartSelectors.selectCart),
  ]).pipe(
    distinctUntilChanged(),
    filter(([_, cartData]) => (cartData?.cartId !== null || cartData?.cartId !== undefined || cartData !== undefined || cartData !== undefined )&& cartData?.cartId !== blankId),
    withLatestFrom(
      this.store.select(ConfigurationSelectors.selectUserInfo),
    ),
    tap(([[formValue, cartData], userInfo]) => {

      this.customBehavior(userInfo);

      // For debugging the checkout form
      if(!environment.production && !environment.isRunningInUnitTest){
        console.log("[Form Log] valueChange value:", formValue);
        console.log("[Form Log] Validators:",this.checkoutFormService.getAllValidators());
        console.log("[Form Log] CartData:", cartData);
        console.log("[Form Log] Form Valid:", this.checkoutFormService.checkoutForm.valid);
      }
    }),
  ), {dispatch: false});

  // Fires when user updates the checkout form
  updateDatabase$ = createEffect(() =>
    this.checkoutFormService.checkoutForm.valueChanges
    .pipe(
      filter((x) => {
        const deliveryItemsFormControl = this.checkoutFormService.checkoutForm.get('shippingAndDelivery.deliveryItems');
        const isLineByLineSelectionPristine = deliveryItemsFormControl.pristine;
        if(!isLineByLineSelectionPristine){
          this.checkoutFormService.checkoutForm.get('shippingAndDelivery.deliveryItems').markAsPristine();
        }
        return x.shippingAndDelivery.cartData !== null &&
               this.checkoutFormService.checkoutForm.dirty &&
               // This prevents the 'For all items' and 'Line-by-line' selection from triggering an update
               isLineByLineSelectionPristine;
      }),
      distinctUntilChanged(),
      debounceTime(500),
      withLatestFrom(
        this.store.select(CustomerSelectors.selectPayers),
        this.store.select(CustomerSelectors.selectShipTo),
        this.store.select(CustomerSelectors.selectStoredPaymentMethods),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CartSelectors.selectDeliveryItems)
      ),
      switchMap(([formGroupValue, payers, shipToCustomer, storedPaymentMethods, branch, deliveryItems]) => {
        this.loaderService.checkoutLoaderSubject.next(true);
        const updateCartRequest = this.getUpdateCartPayload(formGroupValue, payers, shipToCustomer, storedPaymentMethods, branch);
        return this.cartService.updateCart(updateCartRequest).pipe(
          tap((cart) => {
            this.loaderService.checkoutLoaderSubject.next(false);
            this.store.dispatch(CartActions.updateCartSuccess({cart, deliveryItems}));
            this.checkoutFormService.checkoutForm.markAsPristine();
            this.checkoutFormService.checkoutForm.updateValueAndValidity();
          }),
          catchError((error) => {
            this.loaderService.checkoutLoaderSubject.next(false);
            this.toastService.showToast(error, ToastType.Error);
            this.store.dispatch(CartActions.updateCartFailed({error}));
            this.checkoutFormService.checkoutForm.markAsPristine();
            this.checkoutFormService.checkoutForm.updateValueAndValidity();
            return of(error);
          }),
        );
      })
  ), {dispatch: false});

  updateCartOnSubmit$ = createEffect(() => this.actions.pipe(
    ofType(CartActions.preSubmitTransaction),
    withLatestFrom(
      this.store.select(CustomerSelectors.selectPayers),
      this.store.select(CustomerSelectors.selectShipTo),
      this.store.select(CustomerSelectors.selectStoredPaymentMethods),
      this.store.select(BranchSelectors.selectedBranch)
    ),
    switchMap(([{clearShoppingBasket, transactionType}, payers, shipToCustomer, storedPaymentMethods, branch]) => {
      const updateCartParams = this.getUpdateCartPayload(this.checkoutFormService.checkoutForm.value, payers, shipToCustomer, storedPaymentMethods, branch);
      return this.cartService.updateCart(updateCartParams).pipe(
        map(() => CartActions.submitTransaction({clearShoppingBasket, transactionType})),
        catchError((error) => of(CartActions.submitOrderFailed({error})))
      );
    })
  ));

  inBaseUrl$ = fromEventPattern(
    handler => this.router.events.subscribe(handler)
  ).pipe(
    filter(event => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.urlAfterRedirects),
    map(url => this.isInBaseRoute(url)),
    shareReplay(1)
  );


  private isInBaseRoute(url: string): boolean {
    // Assuming your base route is '/'
    return url === '/' || url === '';
  }


  updateCheckoutForm$ = createEffect(() => combineLatest(
    [this.store.select(CartSelectors.selectCart),
     this.activatedRoute.queryParams,
     this.store.select(CustomerSelectors.selectShipTo),
     this.inBaseUrl$]
  ).pipe(
    filter(([cartData, _, __, isBaseUrl]) => cartData?.cartId !== null && cartData?.cartId !== blankId && !isBaseUrl),
    distinctUntilChanged(),
    withLatestFrom(
      this.store.select(CartSelectors.selectDeliverySummary),
      this.store.select(CustomerSelectors.customerState),
      this.store.select(ConfigurationSelectors.isInternal),
      this.loaderService.checkoutLoaderSubject,
      this.store.select(ConfigurationSelectors.isCanadaUser),
      this.store.select(isFeatureActive('ShippingManagement'))
    ),
    tap(() => {
      // The shipto validation fails here after selecting a delivery option, not sure why yet.
      if(this.checkoutFormService.checkoutForm.dirty){
        this.checkoutFormService.checkoutForm.updateValueAndValidity();
      }
    }),
    // This Prevents the form from being overwritten as you are typing
    filter(() => this.checkoutFormService.checkoutForm.pristine),
    tap(([[cartData, queryParams, selectShipTo], deliverySummary, customer, isInternal, isLoading, isCanadaUser, shippingManagementEnabled]) =>
      this.patchForm(cartData, selectShipTo, customer, queryParams, isInternal, isCanadaUser, shippingManagementEnabled)
    ),
    map(([cartData, _]) => {
      this.store.dispatch(CartActions.cacheCart());
      return cartData;
    })
  ), {dispatch: false});

  initiallySetLineByLineRadioButton$ = createEffect(() => this.store.select(CartSelectors.selectCartLines).pipe(
    distinctUntilChanged(),
    tap((lineItems) => {
      const lineItemDeliveryOptions = lineItems?.filter(x => x.partNumber !== "FREIGHT" && x.partNumber !==  "DELIVERY:90")?.map(x => x.deliveryOption);
      const distinctlineItemDeliveryOptions = Array.from(new Set(lineItemDeliveryOptions));
      let lineByLineOption = null;

      if(distinctlineItemDeliveryOptions.length > 1){
        // Set form to line by line
        lineByLineOption = DeliveryItems.lineByLine;
      } else {
        lineByLineOption = DeliveryItems.all;
      }

      const lineByLineOptionControl = this.checkoutFormService.checkoutForm.get('shippingAndDelivery.deliveryItems');
      lineByLineOptionControl.patchValue(lineByLineOption);
    })
  ), {dispatch: false});

  disableDeliveryOptionsWhenFreightLoading$ = createEffect(() => this.store.select(CartSelectors.selectIsShippingRatesLoading).pipe(
    tap((isLoading) => {
      const deliveryTypeControl = this.checkoutFormService.checkoutForm.controls.shippingAndDelivery.controls.deliveryType;
      if(isLoading){
        deliveryTypeControl.disable({emitEvent: false});
      } else {
        deliveryTypeControl.enable({emitEvent: false});
      }
    })
  ), {dispatch: false});

  clearCart$ = createEffect(() => this.actions.pipe(
    ofType(CustomerActions.clearCustomer),
    tap(() => {
      this.store.dispatch(CartActions.clearCartData());
    })
  ), {dispatch: false});

  constructor(
    @Inject(REGEX_PHONE_PATTERN) public regexPhonePattern: string,
    private cartService: CartService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private checkoutFormService: CheckoutFormService,
    private store: Store<AppState>,
    private loaderService: LoaderService,
    private featureFlagService: FeatureFlagService,
    private actions: Actions
  ) { }

  customBehavior(user: User){
    const billToCCControl = this.checkoutFormService.checkoutForm.get('paymentDetails.billToCC');
    const optInControl = this.checkoutFormService.checkoutForm.get('smsOpt.optIn');
    const optInContactNameControl = this.checkoutFormService.checkoutForm.get('smsOpt.contactName');
    const optInMobileNumberControl = this.checkoutFormService.checkoutForm.get('smsOpt.mobileNumber');
    const specialInstructionControl = this.checkoutFormService.checkoutForm.get('additionalDetails.specialInstructions');


    if(
      !!this.checkoutFormService.checkoutForm.value?.shippingAndDelivery?.cartData?.orderNumber &&
      (this.checkoutFormService.checkoutForm.value?.shippingAndDelivery?.cartData?.isLocked &&
      this.checkoutFormService.checkoutForm.value?.shippingAndDelivery?.cartData?.updatedById !== user?.id)
      ){
      specialInstructionControl.disable({emitEvent: false});
    } else {
      specialInstructionControl.enable({emitEvent: false});
    }

    if(optInControl.value){
      optInContactNameControl.enable({emitEvent: false});
      optInMobileNumberControl.enable({emitEvent: false});
    } else {
      optInContactNameControl.disable({emitEvent: false});
      optInMobileNumberControl.disable({emitEvent: false});
    }

    if(this.checkoutFormService.checkoutForm.value.paymentDetails.billTo !== "CASH-CCOnline"){
      billToCCControl.disable({emitEvent: false});
    } else {
      billToCCControl.enable({emitEvent: false});
    }
  }

  patchForm(
    cartData: CartResult,
    selectShipTo: ShipToCustomer,
    customer: CustomerState,
    paymentQueryParams: Params,
    isUserInternal: boolean,
    isCanadaUser: boolean,
    shippingManagementEnabled: boolean
  ): void {
    const poNumber = cartData.poNumber ?? customer.selectedCustomer.customerPONumber;
    const addedCCResult = this.getCCResult(paymentQueryParams['TRANSUCCESS']);
    const i2p = this.getI2Pay(cartData);
    const forAllItems = this.isSameDeliveryForAllLineItems(cartData.lineItems);

    this.checkoutFormService.checkoutForm.patchValue({
      checkoutDetails: {
        poNumber,
        unitNumber: cartData.unitNumber,
        transactionType: cartData.transactionType,
        orderChannel: cartData.orderChannel
      },
      shippingAndDelivery: {
        deliveryItems: forAllItems ? DeliveryItems.all : DeliveryItems.lineByLine,
        deliveryType: cartData.deliveryType?.toLowerCase(),
        cartData: { ...cartData, isUserInternal },
        shipTo: {
          addressNumber: isCashCustomer(customer.selectedCustomer.customerNumber) ? "0000200000" : cartData.shipToAddressNo,
          city: cartData.city,
          customerName: cartData.name1,
          customerName2: cartData.name2,
          customerNumber: cartData.customerNumber,
          mscAccountNumber: cartData.msc,
          phoneNumber: cartData.phone,
          postalCode: cartData.zip?.replace('-0000', ''),
          salesperson: cartData.salesPerson,
          state: cartData.state,
          streetAddress: cartData.address,
          isEdited: cartData.isAlternateAddressEdited,
          isCanadaUser: isCanadaUser,
          shippingManagementEnabled: shippingManagementEnabled
        },
        freightdeliveryoption: cartData?.shippingRate?.service?? null
      },
      paymentDetails: {
        selectedPayment: cartData.paymentSelection,
        billTo: cartData.billToAddressNo,
        billToCC: cartData.paymentSelection,
        i2p,
        i2pEmail: cartData.i2pEmail,
        i2pSms: cartData.i2pSmsNumber,
        addedCCResult
      },
      additionalDetails: {
        altEmail: cartData.alternateEmail === null ? "" : cartData.alternateEmail,
        specialInstructions: cartData.specialInstruction
      },
      smsOpt: {
        optIn: cartData?.smsOptIn,
        contactName: cartData?.smsContactName,
        mobileNumber: cartData?.smsMobileNumber
      },
      customer: customer?.selectedCustomer
    },
      {emitEvent: true, onlySelf: true}
    );

    if (selectShipTo && !customer.shipToEditing && !this.checkoutFormService.checkoutForm.pristine ) {
      this.store.dispatch(CustomerActions.setShipToAddress({
        shipToAddress: selectShipTo
      }));
    }

    if (!cartData.smsOptIn) {
      this.disableSmsOptInFields();
    }

    this.checkoutFormService.checkoutForm.updateValueAndValidity({emitEvent: true});
    this.checkoutFormService.checkoutForm.markAsPristine();
  }

  disableSmsOptInFields() {
    this.checkoutFormService.checkoutForm['controls'].smsOpt['controls'].contactName.disable({emitEvent: false});
    this.checkoutFormService.checkoutForm['controls'].smsOpt['controls'].mobileNumber.disable({emitEvent: false});
  }

  getI2Pay(cartData: any): string {
    let i2p = null;
    if (cartData.i2pEmail !== null && cartData.i2pEmail !== undefined && cartData.i2pEmail !== "") {
      i2p = "email";
    }
    if (cartData.i2pSmsNumber !== null && cartData.i2pSmsNumber !== undefined && cartData.i2pSmsNumber !== "") {
      i2p = "sms";
    }
    if (i2p === null && cartData.paymentSelection === 'CCI2P') {
      i2p = this.checkoutFormService.checkoutForm.value.paymentDetails.i2p;
    }
    return i2p;
  }

  getCCResult(queryParam: string): string | null {
    let addedCCResult: string;

    switch(queryParam) {
      case 'TRUE':
        addedCCResult = addCreditCardTransactionResult.Added;
        break;
      case 'FALSE':
        addedCCResult = addCreditCardTransactionResult.Failed;
        break;
      default:
        addedCCResult = null;
        break;
    }

    return addedCCResult;
  }

  isSameDeliveryForAllLineItems(lineItems: CartResultLineItem[]) {
    return Array
      .from(new Set(lineItems.filter(x => x.partNumber !== "FREIGHT" && x.partNumber !== "DELIVERY:90")
      .map(x => x.deliveryOption)))
      .length <= 1;
  }

  getDeliveryItemsTab(cartData: CartResult, deliveryOptions: string[], isInternalUser: boolean): string {
    let tab = (cartData.deliveryType || !deliveryOptions.length) ? DeliveryItems.all : DeliveryItems.lineByLine;
    const deliveryItems = this.deliveryItems();
    if (deliveryItems !== DeliveryItems.all) {
      tab = deliveryItems;
    }
    if (!isInternalUser) {
      tab = DeliveryItems.all;
    }
    return tab;
  }

  deliveryItems(): DeliveryItems | undefined {
    const x = this.checkoutFormService.checkoutForm.get('shippingAndDelivery')?.get('deliveryItems')?.value;
    return x;
  }

  getPaymentTokenFromQueryParams(queryParams: Params){
    const newStoredPaymentMethod: StoredPaymentMethod = {
      id: "",
      paymentProviderName: "",
      paymentToken: queryParams['REPTOKEN'],
      processorToken: queryParams['TRANSARMORTOKEN'],
      customerId: "",
      driversLicenseNumber: "",
      driversLicenseState: "",
      creditCardAccountExpiration: queryParams['CCEXP'],
      creditCardAccountName: queryParams['REPCCNAME'],
      creditCardNickname: "",
      creditCardAccountNumber: queryParams['CCACCOUNT'],
      tokenBrand: "",
      creditCardType: queryParams['CCCARDTYPE'],
      checkingAccountName: "",
      checkingAccountRoutingNumber: "",
      checkingAccountNumber: "",
      isCheckingAccountBusiness: "",
      isCheckingAccountSavings: ""
    };
    return newStoredPaymentMethod;
  }

  getUpdateCartPayload(formGroupValue: any, payers: Customer[], shipToCustomer: ShipToCustomer, storedPaymentMethods: StoredPaymentMethod[], branch: Branch) {
    const cartData: CartResult = formGroupValue.shippingAndDelivery.cartData;
      let deliveryTypes = null;
      const customerNumber = cartData.customerNumber;
      if(
        this.checkoutFormService.checkoutForm.get('shippingAndDelivery.deliveryItems').value === 'all'){
          deliveryTypes = cartData?.lineItems.map((item) => ({
            cartItemId: item.cartItemId,
            deliveryOption: item.lineId === 801 || item.lineId === 901 ? '' : cartData.deliveryType
          })).reduce((acc, item) => ({ ...acc, [item.cartItemId]: formGroupValue.shippingAndDelivery.deliveryType}), {});
        } else {
          deliveryTypes = cartData?.lineItems.map((item) => ({
            cartItemId: item.cartItemId,
            deliveryOption: item.lineId === 801 || item.lineId === 901 ? '' : DeliveryOptions.find(x => x.key === item.deliveryOption)?.option.toLowerCase()
          })).reduce((acc, item) =>
            ({ ...acc, [item.cartItemId]: item.deliveryOption })
          , {});
        }
      const setDeliveryRequest: SetDeliveryRequest = {
        addDeliveryFees: true,
        branchCode: cartData.branchCode,
        cartId: cartData.cartId,
        headerDeliveryType: formGroupValue.shippingAndDelivery.deliveryType as DeliveryTypes ?? cartData.deliveryType as DeliveryTypes,
        customerNumber,
        deliveryTypes
      };

      const billToCustomerNumber = formGroupValue.paymentDetails.billTo;
      const billTocustomer: Customer = payers.find(payer => payer?.addressNumber === billToCustomerNumber);
      const updateBillToRequest: BillToInfo = billToCustomerNumber === null? null : {
        addressNumber: billToCustomerNumber,
        customerName: billTocustomer.customerName
      };
      const cartShipTo = formGroupValue?.shippingAndDelivery?.shipTo;
      let updateShipToRequest: ShipToCustomer = {...cartShipTo};
      const hasDeliveryOption = ["delivery", "shipto", "shipdirect"].includes(setDeliveryRequest.headerDeliveryType) || setDeliveryRequest.headerDeliveryType === undefined;
      if(!this.doesAtLeastOneFieldHaveValue(updateShipToRequest) && isCashCustomer(updateShipToRequest.customerNumber)){
        if(!hasDeliveryOption && setDeliveryRequest.headerDeliveryType !== null && setDeliveryRequest.headerDeliveryType !== DeliveryTypes.none){
          updateShipToRequest.city = branch.city;
          updateShipToRequest.state = branch.state;
          updateShipToRequest.postalCode = branch.zip;
        } else {
          updateShipToRequest = {} as ShipToCustomer;
        }
      }
      if(!isCashCustomer(shipToCustomer?.addressNumber) && !!shipToCustomer?.addressNumber){
        updateShipToRequest = {...shipToCustomer};
      }
      const storedPaymentMethod = storedPaymentMethods.find(
        (paymentMethod: StoredPaymentMethod) => paymentMethod.id === formGroupValue.paymentDetails.billToCC);


      let i2pEmail = formGroupValue.paymentDetails.i2pEmail;
      let i2pSmsNumber = formGroupValue.paymentDetails.i2pSms;
      if((formGroupValue.paymentDetails.i2p === "email" &&
         formGroupValue.paymentDetails.i2pSms !== null && formGroupValue.paymentDetails.i2pSms !== "") ||
         (formGroupValue.paymentDetails.i2p === "sms" &&
         formGroupValue.paymentDetails.i2pEmail !== null && formGroupValue.paymentDetails.i2pEmail !== "")
      ){
        i2pEmail = null;
        i2pSmsNumber = null;
      }
      const hasFrontCounterSelfPickOrBasketReturnItems =
      (setDeliveryRequest.headerDeliveryType === 'adhoc' || setDeliveryRequest.headerDeliveryType === 'selfpick') ||
      Object.values(setDeliveryRequest.deliveryTypes).some(x => x === 'adhoc' || x === 'selfpick');

      if(hasFrontCounterSelfPickOrBasketReturnItems){
        i2pEmail = null;
        i2pSmsNumber = null;
      }

      const shipToControls = this.checkoutFormService.checkoutForm.controls.shippingAndDelivery.controls.shipTo;
      const shipToTouched = {
        addressNumber: shipToControls.controls.addressNumber.dirty,
        city: shipToControls.controls.city.dirty,
        customerName: shipToControls.controls.customerName.dirty,
        customerName2: shipToControls.controls.customerName2.dirty,
        customerNumber: shipToControls.controls.customerNumber.dirty,
        isEdited: shipToControls.controls.isEdited.dirty,
        mscAccountNumber: shipToControls.controls.mscAccountNumber.dirty,
        phoneNumber: shipToControls.controls.phoneNumber.dirty,
        postalCode: shipToControls.controls.postalCode.dirty,
        salesperson: shipToControls.controls.salesperson.dirty,
        state: shipToControls.controls.state.dirty,
        streetAddress: shipToControls.controls.streetAddress.dirty
      };

      // When selecting from the modal the isEdited field is always set to false
      // We need to determine here wether a manual entry of the shipto form occured
      const shipToManuallyModified = new Set(Object.values(shipToTouched)).size > 1;

      const anyFieldsDiffer =
        shipToControls.value.customerName !== cartData.customerName ||
        shipToControls.value.streetAddress !== cartData.address ||
        shipToControls.value.city !== cartData.city ||
        shipToControls.value.state !== cartData.state ||
        shipToControls.value.phoneNumber !== cartData.phone ||
        shipToControls.value.mscAccountNumber !== cartData.msc;

      if(shipToManuallyModified){
        // If manually updated use form values
        const updatedShipTo = shipToControls.value as ShipToCustomer;
        updateShipToRequest = {...updatedShipTo, isEdited: shipToManuallyModified};
        this.checkoutFormService.checkoutForm.controls.shippingAndDelivery.controls.shipTo.controls.isEdited.setValue(shipToManuallyModified, {emitEvent: false});
      } else if(anyFieldsDiffer) {
        const updatedShipTo = shipToControls.value as ShipToCustomer;
        updateShipToRequest = {...updatedShipTo};
      }

      const updateCartRequest = {
        alternateEmail: formGroupValue.additionalDetails.altEmail,
        cartId: formGroupValue.shippingAndDelivery.cartData?.cartId,
        poNumber: formGroupValue.checkoutDetails?.poNumber?.toUpperCase(),
        specialInstruction: formGroupValue.additionalDetails.specialInstructions,
        unitNumber: formGroupValue.checkoutDetails?.unitNumber,
        setDeliveryRequest,
        updateBillToRequest,
        updateShipToRequest,
        transactionType: formGroupValue.checkoutDetails.transactionType?.toString(),
        orderChannel: formGroupValue.checkoutDetails.orderChannel?.toString(),
        i2pEmail,
        i2pSmsNumber,
        paymentMethod: formGroupValue.paymentDetails.billToCC,
        storedToken: storedPaymentMethod ?? null,
        contactName: formGroupValue.smsOpt?.optIn? formGroupValue.smsOpt?.contactName: null,
        mobileNumber: formGroupValue.smsOpt?.optIn? formGroupValue.smsOpt?.mobileNumber: null,
        optIn: formGroupValue.smsOpt?.optIn
      } as UpdateCartRequest;

      if( (updateBillToRequest?.addressNumber !== "CASH-CCOnline" &&
           updateCartRequest.paymentMethod !== null ) ||
           updateCartRequest.transactionType === OrderType.Quote){
        updateCartRequest.paymentMethod = null;
        updateCartRequest.storedToken = null;
        updateCartRequest.i2pEmail = null;
        updateCartRequest.i2pSmsNumber = null;
      }

      if (
        this.checkoutFormService.checkoutForm.get('paymentDetails.billToCC').value !== null &&
        this.checkoutFormService.checkoutForm.get('paymentDetails.billToCC').value !== '' &&
        updateBillToRequest?.addressNumber !== "CASH-CCOnline"
        ) {
          this.checkoutFormService.checkoutForm.get('paymentDetails.billToCC').setValue('');
          this.checkoutFormService.checkoutForm.markAsPristine();
      }

      if (
        this.checkoutFormService.checkoutForm.get('paymentDetails.billToCC').value !== null &&
        this.checkoutFormService.checkoutForm.get('paymentDetails.billToCC').value !== '' &&
        updateBillToRequest?.addressNumber === "CASH-CCOnline"
        ) {
          this.checkoutFormService.checkoutForm.markAsPristine();
      }

      return updateCartRequest;
  }

  doesAtLeastOneFieldHaveValue(shipToCustomer: ShipToCustomer) {
    if(Object.values(shipToCustomer).length === 0){
      return false;
    }
    const cityHasValue = shipToCustomer.city !== null && shipToCustomer.city !== '';
    const customerNameHasValue = shipToCustomer.customerName !== null && shipToCustomer.customerName !== '';
    const customerName2HasValue = shipToCustomer.customerName2 !== null && shipToCustomer.customerName2 !== '';
    const mscAccountNumberHasValue = shipToCustomer.mscAccountNumber !== null && shipToCustomer.mscAccountNumber !== '';
    const phoneNumberHasValue = shipToCustomer.phoneNumber !== null && shipToCustomer.phoneNumber !== '';
    const stateHasValue = shipToCustomer.state !== null && shipToCustomer.state !== '';
    const streetAddressHasValue = shipToCustomer.streetAddress !== null && shipToCustomer.streetAddress !== '';
    const postalCodeHasValue = shipToCustomer.postalCode !== null && shipToCustomer.postalCode !== '';
    return cityHasValue ||
           customerNameHasValue ||
           customerName2HasValue ||
           mscAccountNumberHasValue ||
           phoneNumberHasValue ||
           stateHasValue ||
           streetAddressHasValue ||
           postalCodeHasValue;
  }

}

